import { Container } from "unstated"
import { get } from "lodash"

import api from "../utils/api"
import deserialize from "../utils/deserialize"
import debounce from "../utils/debounce"
import { toastSuccess, toastError } from "../utils/myToasts"

import authStore from "./auth"

const genericError = "Oops! We couldn't process your request!"

class RockPointsStore extends Container {
  constructor(props) {
    super(props)

    this.redeemPoints = debounce(this.redeemPoints)
    this.fetchHistory = debounce(this.fetchHistory)
    this.fetchData = debounce(this.fetchData)
  }

  meta = {
    fetched: false,
  }

  state = {
    history: [],
  }

  get preCreditDigit() {
    return get(authStore, "state.user.mp_managed_subscription", false) ? 1 : -1
  }

  get nextBillDiscount() {
    return (
      this.preCreditDigit *
      get(authStore, "state.user.next_invoice_discount", 0)
    )
  }

  get rockPoints() {
    return get(authStore, "state.user.rock_points", 0)
  }

  get rockPointsRate() {
    return get(authStore, "state.user.per_dollor_rock_points", 10)
  }

  get rockPointsUSD() {
    return this.rockPoints / this.rockPointsRate
  }

  fetchData = (...args) => {
    authStore.getProfile()
    return this.fetchHistory(...args)
  }

  fetchHistory = async skipCheck => {
    if (!skipCheck && this.meta.fetched) return

    this.meta.fetched = true

    try {
      const res = await api.get("/account/points_history")
      const history = await deserialize(res.data)
      await this.setState({ history })
      return history
    } catch (e) {
      this.meta.fetched = false
      console.error(e)
    }
  }

  redeemPoints = async (points, cb) => {
    try {
      const res = await api.put(`/account/redeem_rock_points`, {
        rock_points: points,
      })
      toastSuccess(
        get(
          res,
          "data.message",
          "Discount will be applied automatically on your next bill!"
        )
      )
      await this.fetchHistory(true)
      return authStore.getProfile(undefined, cb)
    } catch (e) {
      const message = get(e, "response.data.errors.0.title", genericError)
      toastError(message)
      console.error(e)
    }
  }

  createCharge = async (amount, signatureEmail) => {
    try {
      const res = await api.post(`/coinbase/charge`, {
        amount,
        electronic_signature_email: signatureEmail,
      })
      return res.data
    } catch (e) {
      const message = get(e, "response.data.errors.0.title", genericError)
      toastError(message)
      console.error(e)
    }
  }

  createInvoice = async (amount, greToken, v2) => {
    try {
      const res = await api.post(`/invoices`, {
        amount,
        gre_token: greToken,
        v2,
      })
      if (res.data.success && window.Stripe) {
        const stripe = window.Stripe(process.env.STRIPE_API_KEY)
        stripe.redirectToCheckout({ sessionId: res.data.session })
      }
    } catch (err) {
      console.error(err)
      const message = get(err, "response.data", "Some error occured!")
      toastError(message)
    }
  }

  addCreditCoinify = async (amount, signatureEmail) => {
    try {
      const res = await api.post(`/coinify/addCredit`, {
        amount,
        electronic_signature_email: signatureEmail,
      })
      return res.data
    } catch (e) {
      const message = get(e, "response.data.errors.0.title", genericError)
      toastError(message)
      console.error(e)
    }
  }
}

export default RockPointsStore
